
import { Component, Vue } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import NewMessagesBadge from '@/components/NewMessagesBadge.vue'
import ContactDialog from '@/components/ContactDialog.vue'
import { namespace } from 'vuex-class'

const Auth = namespace('auth')

@Component({
	components: {
		ContactDialog,
		Icon,
		NewMessagesBadge
	}
})
export default class DesktopNavigation extends Vue {
	@Auth.Getter('navigationItems')
	navigationItems

	@Auth.Getter('isSkipper')
	isSkipper

	mini: boolean = true
	breakPoint: number = 0
	contactDialogOpened: boolean = false

	constructor() {
		super()

		this.breakPoint = Number(process.env.VUE_APP_BREAKPOINT_DESKTOP)
	}

	toggleMenu() {
		this.mini = !this.mini
	}

	@Auth.Action('logout')
	logout: any
}
